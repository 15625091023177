import { GetStaticPathsContext, GetStaticPathsResult, GetStaticPropsContext, GetStaticPropsResult } from "next";
import { DrupalNode, DrupalTranslatedPath } from "next-drupal";

import { NextRouter, useRouter } from "next/router";

import Layout from "@components/Layout/index";
import Node from "@components/Node";
import { drupal } from "@lib/drupal";
import { getMenus } from "@lib/get-menus";

import AuthSection from "@components/Authentication/AuthSection";
import MetaTags from "@components/Common/MetaTags/MetaTags";
import { setFormData } from "@helper/formData";
import { LangDataProps, genLangData } from "@helper/langData";
import { paragraph_include } from "@helper/paragraph";
import { protectedPath, revalidate404, revalidatePage } from "@lib/costants";
import { useSession } from "next-auth/react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { ParsedUrlQuery } from "querystring";
import { useEffect } from "react";

const RESOURCE_TYPES: string[] = ["node--page", "node--implants", "taxonomy_term--implat_types"];
const EXCLUDED_PATHS: string[] = ['next-journal', 'news', 'news-data', 'search-data', 'category-companies-data', 'category-public-administrations-data'];

const isExcludedPath = (path: string | {
  params: ParsedUrlQuery;
  locale?: string;
}) => EXCLUDED_PATHS.includes(typeof path === 'string' ? path : path?.params?.slug.at(0));

interface NodePageProps {
  node: DrupalNode,
  langData: LangDataProps[],
  menus
}

export default function NodePage({ node, langData, menus }: NodePageProps) {
  const router: NextRouter = useRouter();
  const { status } = useSession();

  const isProtectedPath: boolean = protectedPath.includes(router.asPath)
  const isAuthenticated: boolean = status === 'authenticated'

  useEffect(() => {setFormData(node)}, [router.asPath, node])

  if (!node) return null

  return (
    <Layout menus={menus} node={node} langData={langData}>
      <MetaTags node={node} />

      {isProtectedPath && !isAuthenticated ? <AuthSection /> : <Node node={node} isProtectedPath={isProtectedPath} />}

    </Layout>
  )
}

export async function getStaticPaths(context: GetStaticPathsContext): Promise<GetStaticPathsResult> {
  const rawPaths = await drupal.getStaticPathsFromContext(RESOURCE_TYPES, context);
  const validPaths = rawPaths.filter((path) =>  !isExcludedPath(path));
  return {
    paths: validPaths,
    fallback: 'blocking',
  }
}

export async function getStaticProps( context: GetStaticPropsContext ): Promise<GetStaticPropsResult<NodePageProps>> {
  const path: DrupalTranslatedPath = await drupal.translatePathFromContext(context);

  if (
    !path ||
    path.resolved == `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/it/pagina-non-trovata` ||
    path.resolved == `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/en/page-not-found`
    ) {
      console.error('Invalid path', path)
    return {
      notFound: true,
      revalidate: revalidate404
    }
  }

  const nodeType: string = path?.jsonapi?.resourceName
  const node: DrupalNode = await drupal.getResourceFromContext<DrupalNode>( path, context, {
    params: { include: paragraph_include[nodeType] }
  })
  
  // 404 if node have disable field, use only for views node in listings
  if(node?.field_disabled) {
    console.log('Node disabled', node)
    return {
      notFound: true,
      revalidate: revalidate404
    }
  }

  const langData: LangDataProps[] = await genLangData(node, context)

  // At this point, we know the path exists and it points to a resource.
  // If we receive an error, it means something went wrong on Drupal.
  // We throw an error to tell revalidation to skip this for now.
  // Revalidation can try again on next request.
  if (!node) {
    throw new Error(`Failed to fetch resource: ${path.jsonapi.individual}`)
  }

  const fieldParagraph = node?.field_paragraph;
  const paragraphTabs = fieldParagraph?.filter(item => item.type === 'paragraph--tab');
  
  const fetchResource = async (tabItem) => {
    if (tabItem?.field_reusable_paragraph_holder?.id) {
      const resource = await drupal.getResource('node--paragraphs_holder', tabItem.field_reusable_paragraph_holder.id, {
        params: {
          include: paragraph_include["node--paragraphs_holder"],
        },
        locale: context.locale,
        defaultLocale: context.defaultLocale,
      });
      return resource;
    }
    return null;
  };
  
  if (paragraphTabs?.length) {
    for (const tab of paragraphTabs) {
      const fetchPromises = tab.field_tab_item.map(tabItem => fetchResource(tabItem));
  
      const resources = await Promise.all(fetchPromises);
  
      resources.forEach((resource, index) => {
        if (resource) {
          tab.field_tab_item[index].field_reusable_paragraph_holder = resource;
        }
      });
    }
  }

  // If we're not in preview mode and the resource is not published,
  // Return page not found.
  if (!context.preview && node?.status === false) {
    console.log('Preview mode', node)
		return {
      notFound: true,
      revalidate: revalidate404
    }
  }

  const menus = await getMenus(context)

  return {
    props: {
      ...(await serverSideTranslations(context.locale)),
      node,
      langData,
      menus
    },
    revalidate: revalidatePage,
  }
}
